function __form_ajax(form, spinnerContainer, callback, pre) {
    form.find('input').on('change, keyup', function () {
        if ($(this).length > 0) {
            $(this).parent().find('.errors').remove();
            $(this).parent().find('.form-error').removeClass('form-error');
        }
    });

    form.on('submit', function () {
        form.find('.errors').remove();
        form.find('.form-error').removeClass('form-error');

        grecaptcha.execute(
            '6Lf2vWIaAAAAAJySwHJib2OdXrmewUyVd8kKFFzi',
            {action: 'submit'}
        ).then(
            function (token) {

                form.find("input[name=gCaptcha]")[0].value = token;

                placeLoaderOnElement(spinnerContainer);
                $.ajax({
                    type: 'POST',
                    url: form.attr('action'),
                    data: form.serialize(),
                    dataType: 'json',
                    success: function (result) {
                        removeLoaderOnElement(spinnerContainer);

                        if (result.success === true) {
                            callback();
                        } else {
                            if (result.error) {
                                result.errors.push(result.error);
                            }
                            $.each(result.errors, function (key, value) {
                                var el = $('#' + pre + key);
                                var ul = $('<ul/>').addClass('errors');
                                if (value instanceof Array) {
                                    $.each(value, function (key, value) {
                                        var li = $('<li/>').addClass('formError');
                                        li.text(value);
                                        li.appendTo(ul);
                                    });
                                } else {
                                    var li = $('<li/>').addClass('formError');
                                    li.text(value);
                                    li.appendTo(ul);
                                }
                                $('.form-error-message').modal('show');

                                ul.insertAfter(el);
                                el.addClass('form-error');
                            });
                        }
                    },
                    error: function () {
                        removeLoaderOnElement(spinnerContainer);
                        $('.form-error-message').modal('show');
                    }
                });

            }
        ).catch(
            function (err) {
                console.log(err);
                alert("Une erreur interne est survenue lors de la soumission de votre message");
            }
        );



        return false;
    });
}
